import './index.css';

import {
  FILTER_ALL,
  FILTER_MULTI_USED,
  FILTER_NOT_USED,
  FILTER_USED
} from '../../constant/filter';
import { SORT_ALFABETIC, SORT_DATE, SORT_MANUAL } from '../../constant/sort';
import {
  THEME_CLASS_ICON_CHECK,
  THEME_CLASS_ICON_GROUP_ALL_PHOTOS,
  THEME_CLASS_ICON_GROUP_MANUAL_PHOTOS,
  THEME_CLASS_ICON_GROUP_MULTI_USED_PHOTOS,
  THEME_CLASS_ICON_GROUP_NOT_USED_PHOTOS,
  THEME_CLASS_ICON_GROUP_USED_PHOTOS,
  THEME_CLASS_ICON_SORT_ALFABETIC,
  THEME_CLASS_ICON_SORT_DATE,
  THEME_CLASS_MENU,
  THEME_CLASS_MENU_ADD_IMAGE,
  THEME_CLASS_MENU_ADD_IMAGE_ANIMATED,
  THEME_CLASS_MENU_ADD_IMAGE_DISABLED,
  THEME_CLASS_MENU_ADD_THEME_IMAGE,
  THEME_CLASS_MENU_ADD_THEME_IMAGE_ANIMATED,
  THEME_CLASS_MENU_GROUP_IMAGE,
  THEME_CLASS_MENU_SORT_IMAGE,
  THEME_CLASS_MENU_GROUP_IMAGE_MASK,
  THEME_CLASS_ICON_GROUP_ALL_PHOTOS_MASK,
  THEME_CLASS_ICON_GROUP_USED_PHOTOS_MASK,
  THEME_CLASS_ICON_GROUP_MULTI_USED_PHOTOS_MASK
} from '../../constant/button';

import NavItem from '../nav-item';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Select from '../select';
import { getRetiraImagens } from '../../utils/textosVariaveis';
import { screenSizePropTypes } from '../../proptypes';
import { habilitarBiblioteca } from '../../utils/separadorProdutos';

function groupOptions(prodType) {
  return [
    {
      id: FILTER_ALL,
      label: 'Todas',
      img:
        prodType === 'mask'
          ? THEME_CLASS_ICON_GROUP_ALL_PHOTOS_MASK
          : THEME_CLASS_ICON_GROUP_ALL_PHOTOS
    },
    {
      id: FILTER_USED,
      label: 'Usadas',
      img:
        prodType === 'mask'
          ? THEME_CLASS_ICON_GROUP_USED_PHOTOS_MASK
          : THEME_CLASS_ICON_GROUP_USED_PHOTOS
    },
    {
      id: FILTER_NOT_USED,
      label: 'Não usadas',
      img: THEME_CLASS_ICON_GROUP_NOT_USED_PHOTOS
    },
    {
      id: FILTER_MULTI_USED,
      label: 'Multi usadas',
      img:
        prodType === 'mask'
          ? THEME_CLASS_ICON_GROUP_MULTI_USED_PHOTOS_MASK
          : THEME_CLASS_ICON_GROUP_MULTI_USED_PHOTOS
    }
  ];
}

const sortOptions = [
  {
    id: SORT_MANUAL,
    label: 'Manual',
    img: THEME_CLASS_ICON_GROUP_MANUAL_PHOTOS
  },
  {
    id: SORT_ALFABETIC,
    label: 'Alfabética',
    img: THEME_CLASS_ICON_SORT_ALFABETIC
  },
  { id: SORT_DATE, label: 'Por data', img: THEME_CLASS_ICON_SORT_DATE }
];

const NavImage = ({
  imagesLength,
  inputFileChangeHandler,
  deleteAll,
  sortChangeHandler,
  filterChangeHandler,
  selectedSortId,
  selectedFilterId,
  screenSize,
  prodType,
  toggleBibliotecaTemas,
  maxImageCount
}) => (
  <div className="nav-item-add-photo">
    <input
      type="file"
      name="image-input-file"
      id="image-input-file"
      multiple
      accept="image/*"
      onChange={inputFileChangeHandler}
      disabled={imagesLength >= maxImageCount}
    />
    <label
      data-tip="Coloca suas imagens na Mesa"
      data-place="right"
      className="label-add-photo"
      htmlFor="image-input-file"
      style={{ marginRight: `${screenSize.width * 0.019}px` }}
    >
      <div
        className={`${
          imagesLength
            ? imagesLength >= maxImageCount
              ? THEME_CLASS_MENU_ADD_IMAGE_DISABLED
              : THEME_CLASS_MENU_ADD_IMAGE
            : THEME_CLASS_MENU_ADD_IMAGE_ANIMATED
        } div-add-menu`}
      />
      <span data-animation={!imagesLength} style={{ marginTop: `2px` }}>
        Adicionar
      </span>
    </label>

    {habilitarBiblioteca(prodType) && (
      <label
        data-tip="Adiciona na Mesa, imagens de nossa biblioteca temática"
        data-place="right"
        className="label-add-photo"
        // htmlFor="image-input-file"
        style={{ marginRight: `${screenSize.width * 0.019}px` }}
        onClick={() => toggleBibliotecaTemas()}
      >
        <div
          className={`${
            imagesLength
              ? THEME_CLASS_MENU_ADD_THEME_IMAGE
              : THEME_CLASS_MENU_ADD_THEME_IMAGE_ANIMATED
          } div-add-menu`}
        />
        <span data-animation={!imagesLength} style={{ marginTop: `2px` }}>
          Biblioteca
        </span>
      </label>
    )}

    <span
      data-tip={`Retira todas as imagens não usadas ${getRetiraImagens()} da Mesa`}
      data-place="right"
    >
      <NavItem
        theme={THEME_CLASS_MENU}
        label="Remover"
        clickHandler={deleteAll}
        screenSize={screenSize}
      />
    </span>
    <span
      data-tip="Define o critério de exibição das imagens"
      data-place="right"
    >
      <Select
        name="group"
        cover={
          prodType === 'mask'
            ? THEME_CLASS_MENU_GROUP_IMAGE_MASK
            : THEME_CLASS_MENU_GROUP_IMAGE
        }
        label="Agrupar"
        checkedId={selectedFilterId}
        changeHandler={filterChangeHandler}
        selectedIcon={THEME_CLASS_ICON_CHECK}
        options={groupOptions(prodType)}
        screenSize={screenSize}
        type={'filter-img'}
      />
    </span>
    <span
      data-tip="Sequencia as imagens por diferentes critérios"
      data-place="right"
    >
      <Select
        name="sort"
        cover={THEME_CLASS_MENU_SORT_IMAGE}
        label="Ordenar"
        checkedId={selectedSortId}
        changeHandler={sortChangeHandler}
        selectedIcon={THEME_CLASS_ICON_CHECK}
        options={sortOptions}
        screenSize={screenSize}
        type={'filter-img'}
      />
    </span>
    <ReactTooltip />
  </div>
);

NavImage.propTypes = {
  imagesLength: PropTypes.number.isRequired,
  inputFileChangeHandler: PropTypes.func.isRequired,
  deleteAll: PropTypes.func.isRequired,
  sortChangeHandler: PropTypes.func.isRequired,
  filterChangeHandler: PropTypes.func.isRequired,
  selectedSortId: PropTypes.string.isRequired,
  selectedFilterId: PropTypes.string.isRequired,
  screenSize: screenSizePropTypes,
  prodType: PropTypes.string.isRequired,
  toggleBibliotecaTemas: PropTypes.func.isRequired
};

export default NavImage;
