import * as actionItem from '../constant/action-type/item';

export const setFinished = (state = true) => ({
  type: actionItem.SET_FINISHED,
  payload: { state }
});

export const setEanBlob = url => ({
  type: actionItem.SET_EAN_URL,
  payload: { url }
});

export const setImagesUploaded = (state = true) => ({
  type: actionItem.SET_IMAGES_UPLOADED,
  payload: { state }
});

export const setMetadatasUploaded = (state = true) => ({
  type: actionItem.SET_METADATAS_UPLOADED,
  payload: { state }
});

export const updateOrderID = id => ({
  type: actionItem.UPDATE_ORDER_ID,
  payload: { id }
});

export const toggleIs3D = () => ({
  type: actionItem.TOGGLE_IS_3D
});

export const updatePageSetId = id => ({
  type: actionItem.UPDATE_PAGE_SET_ID,
  payload: { id }
});

export const updatePageFormat = format => ({
  type: actionItem.UPDATE_PAGE_FORMAT,
  payload: { format }
});

export const updatePageIDB = () => ({
  type: actionItem.UPDATE_PAGE_IDB
});

export const updateLayoutFilterPhotoNumber = n => ({
  type: actionItem.UPDATE_LAYOUT_FILTER_PHOTO_NUMBER,
  payload: { n }
});

export const sagaUpdateLayoutFilterPhotoNumber = type => ({
  type: actionItem.SAGA_UPDATE_LAYOUT_FILTER_PHOTO_NUMBER,
  payload: { type }
});

export const updateLayoutFilterText = value => ({
  type: actionItem.UPDATE_LAYOUT_FILTER_TEXT,
  payload: { value }
});

export const updateLayoutFilterApplyMethod = value => ({
  type: actionItem.UPDATE_LAYOUT_FILTER_APPLY_METHOD,
  payload: { value }
});

export const resetLayoutFilterApplyMethod = value => ({
  type: actionItem.RESET_LAYOUT_FILTER_APPLY_METHOD,
  payload: { value }
});

export const updateLayoutFilterSide = value => ({
  type: actionItem.UPDATE_LAYOUT_FILTER_SIDE,
  payload: { value }
});

export const updateLayoutFilterPages = page => ({
  type: actionItem.UPDATE_LAYOUT_FILTER_PAGES,
  payload: { page }
});

export const sagaAdjustLayoutsFilter = () => ({
  type: actionItem.SAGA_ADJUST_LAYOUTS_FILTER
});

export const adjustLayoutsFilter = (styleId, shapesNumber, text) => ({
  type: actionItem.ADJUST_LAYOUTS_FILTER,
  payload: { styleId, shapesNumber, text }
});

export const toggleMenuLayouts = value => ({
  type: actionItem.TOGGLE_MENU_LAYOUTS,
  payload: { value }
});

export const updateIsCoupon = isCoupon => ({
  type: actionItem.UPDATE_IS_COUPON,
  payload: { isCoupon }
});

export const updateIsPrint = isPrint => ({
  type: actionItem.UPDATE_IS_PRINT,
  payload: { isPrint }
});

export const setTamanhoColeira = tamanhoColeira => ({
  type: actionItem.SET_TAMANHO_COLEIRA,
  payload: { tamanhoColeira }
});

export const toggleModoSobFivela = () => ({
  type: actionItem.TOGGLE_MODO_SOB_FIVELA
});

export const setModoSobFivela = modoSobFivela => ({
  type: actionItem.SET_MODO_SOB_FIVELA,
  payload: { modoSobFivela }
});

export const updateIsThumb = isThumb => ({
  type: actionItem.UPDATE_IS_THUMB,
  payload: { isThumb }
});

export const updateIsBack = isBack => ({
  type: actionItem.UPDATE_IS_BACK,
  payload: { isBack }
});

export const updateIsSpineModified = isSpineModified => ({
  type: actionItem.UPDATE_IS_SPINE_MODIFIED,
  payload: { isSpineModified }
});

export const addImages = (result, entities) => ({
  type: actionItem.ADD_IMAGES,
  mergeLib: true,
  payload: { result, entities }
});

export const addPages = (result, entities, item = true) => ({
  type: actionItem.ADD_PAGES,
  mergeLib: true,
  payload: { result, entities, item }
});

export const removePage = id => ({
  type: actionItem.REMOVE_PAGE,
  payload: { id }
});

export const removePages = () => ({
  type: actionItem.REMOVE_PAGES
});

export const updateSortMethod = sortMethod => ({
  type: actionItem.UPDATE_SORT_METHOD,
  payload: { sortMethod }
});

export const updateFilterMethod = filterMethod => ({
  type: actionItem.UPDATE_FILTER_METHOD,
  payload: { filterMethod }
});

export const setPageIndex = index => ({
  type: actionItem.SET_PAGE_INDEX,
  payload: { index }
});

export const setPrintPage = index => ({
  type: actionItem.SET_PRINT_PAGE,
  payload: { index }
});

export const toggleRemovePages = () => ({
  type: actionItem.TOGGLE_REMOVE_PAGES
});

export const setPagesToRemove = pages => ({
  type: actionItem.SET_PAGES_TO_REMOVE,
  payload: { pages }
});

export const unsetPagesToRemove = pages => ({
  type: actionItem.UNSET_PAGES_TO_REMOVE,
  payload: { pages }
});

export const setAllUserProducts = products => ({
  type: actionItem.SET_ALL_USER_PRODUCTS,
  payload: { products }
});

export const setUserProduct = (index, productId, products, command) => ({
  type: actionItem.SET_USER_PRODUCT,
  payload: { index, productId, products, command }
});

export const setSelectedProduct = product => ({
  type: actionItem.SET_SELECTED_PRODUCT,
  payload: { product }
});

export const setFundoColorFront = fundoColor => ({
  type: actionItem.SET_FUNDO_COLOR_FRONT,
  payload: { fundoColor }
});

export const setFundoColorBack = fundoColor => ({
  type: actionItem.SET_FUNDO_COLOR_BACK,
  payload: { fundoColor }
});

export const setTipoFundoFront = tipoFundo => ({
  type: actionItem.SET_TIPO_FUNDO_FRONT,
  payload: { tipoFundo }
});

export const setTipoFundoBack = tipoFundo => ({
  type: actionItem.SET_TIPO_FUNDO_BACK,
  payload: { tipoFundo }
});

export const setProductStatus = status => ({
  type: actionItem.SET_PRODUCT_STATUS,
  payload: status
});
