import * as R from 'ramda';
import * as actionItem from '../constant/action-type/item';

import { FETCH_FROM_IDB } from '../constant/action-type/general';
import { FILTER_ALL } from '../constant/filter';
import { SORT_MANUAL } from '../constant/sort';
import { calculateTotalPrice } from '../selector/price';

const MAX_DISK_SPACE = 2097152; //2GB
const MAX_IMAGE_COUNT = 300;

const initalState = {
  finished: false,
  ean: null,
  imagesUploaded: false,
  metadatasUploaded: false,
  is3D: false,
  pageSetIndex: 0,
  printPage: null,
  pageFormat: '',
  pageSetId: 0,
  isCoupon: false,
  isPrint: false,
  modoSobFivela: false,
  tamanhoColeira: 18,
  fundoColorFront: '#FFFFFF',
  fundoColorBack: '#FFFFFF',
  tipoFundoFront: 'todos',
  tipoFundoBack: 'todos',
  isThumb: false,
  isBack: false,
  sortMethod: SORT_MANUAL,
  filterMethod: FILTER_ALL,
  isSpineModified: false,
  navigation: {
    index: 0
  },
  layoutsFilter: {
    photos: 1,
    text: 'sem',
    applyMethod: 'somente a página atual',
    layoutSelected: null,
    menuText: false,
    menuApplyMethod: false,
    side: null,
    pages: []
  },
  image: [],
  page: [],
  removePages: {
    active: false,
    pages: []
  },
  totalPrice: 0,
  products: [],
  maxUserDiskSpace: MAX_DISK_SPACE,
  maxImageCount: MAX_IMAGE_COUNT
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case FETCH_FROM_IDB:
      action.payload.item.ean = state.ean;
      action.payload.item.isPrint = state.isPrint;
      action.payload.item.isThumb = state.isThumb;
      if (state.isPrint) {
        action.payload.item.printPage = state.printPage;
        action.payload.item.navigation.index = state.navigation.index;
      }
      if (state.isBack) action.payload.item.isBack = state.isBack;
      action.payload.item.metadatasUploaded =
        action.payload.item.imagesUploaded;
      return action.payload.item;
    case actionItem.SET_FINISHED:
      return {
        ...state,
        finished: action.payload.state
      };
    case actionItem.SET_EAN_URL:
      return {
        ...state,
        ean: action.payload.url
      };
    case actionItem.SET_IMAGES_UPLOADED:
      return {
        ...state,
        imagesUploaded: action.payload.state
      };
    case actionItem.SET_METADATAS_UPLOADED:
      return {
        ...state,
        metadatasUploaded: action.payload.state
      };
    case actionItem.TOGGLE_IS_3D:
      return {
        ...state,
        is3D: !state.is3D
      };
    case actionItem.SET_PRINT_PAGE:
      return {
        ...state,
        printPage: action.payload.index
      };
    case actionItem.ADD_IMAGES:
      return {
        ...state,
        image: R.concat(state.image, action.payload.result)
      };
    case actionItem.UPDATE_PAGE_SET_ID:
      return {
        ...state,
        lastPageSetId: state.pageSetId,
        pageSetId: action.payload.id
      };
    case actionItem.UPDATE_PAGE_FORMAT:
      return {
        ...state,
        pageFormat: action.payload.format
      };
    case actionItem.UPDATE_LAYOUT_FILTER_PHOTO_NUMBER:
      return {
        ...state,
        layoutsFilter: {
          ...state.layoutsFilter,
          photos: action.payload.n
        }
      };
    case actionItem.UPDATE_LAYOUT_FILTER_TEXT:
      return {
        ...state,
        layoutsFilter: {
          ...state.layoutsFilter,
          text: action.payload.value
        }
      };
    case actionItem.UPDATE_LAYOUT_FILTER_APPLY_METHOD:
      return {
        ...state,
        layoutsFilter: {
          ...state.layoutsFilter,
          applyMethod: action.payload.value
        }
      };
    case actionItem.RESET_LAYOUT_FILTER_APPLY_METHOD:
      return {
        ...state,
        layoutsFilter: {
          ...state.layoutsFilter,
          applyMethod: action.payload.value
        }
      };
    case actionItem.TOGGLE_MENU_LAYOUTS:
      switch (action.payload.value) {
        case 'TEXT':
          return {
            ...state,
            layoutsFilter: {
              ...state.layoutsFilter,
              menuText: !state.layoutsFilter.menuText
            }
          };
        case 'APPLYMETHOD':
          return {
            ...state,
            layoutsFilter: {
              ...state.layoutsFilter,
              menuApplyMethod: !state.layoutsFilter.menuApplyMethod
            }
          };
        default:
          return state;
      }
    case actionItem.UPDATE_LAYOUT_FILTER_SIDE:
      return {
        ...state,
        layoutsFilter: {
          ...state.layoutsFilter,
          side: action.payload.value
        }
      };
    case actionItem.UPDATE_LAYOUT_FILTER_PAGES:
      return {
        ...state,
        layoutsFilter: {
          ...state.layoutsFilter,
          pages: state.layoutsFilter.pages.includes(action.payload.page)
            ? state.layoutsFilter.pages
            : [...state.layoutsFilter.pages, action.payload.page]
        }
      };
    case actionItem.ADJUST_LAYOUTS_FILTER:
      return {
        ...state,
        layoutsFilter: {
          ...state.layoutsFilter,
          layoutSelected: action.payload.styleId,
          photos: action.payload.shapesNumber,
          text: action.payload.text
        }
      };
    case actionItem.UPDATE_IS_COUPON:
      return {
        ...state,
        isCoupon: action.payload.isCoupon
      };
    case actionItem.UPDATE_IS_PRINT:
      return {
        ...state,
        isPrint: action.payload.isPrint
      };
    case actionItem.SET_TAMANHO_COLEIRA:
      return {
        ...state,
        tamanhoColeira: action.payload.tamanhoColeira
      };
    case actionItem.SET_FUNDO_COLOR_FRONT:
      return {
        ...state,
        fundoColorFront: action.payload.fundoColor
      };
    case actionItem.SET_FUNDO_COLOR_BACK:
      return {
        ...state,
        fundoColorBack: action.payload.fundoColor
      };
    case actionItem.SET_TIPO_FUNDO_FRONT:
      return {
        ...state,
        tipoFundoFront: action.payload.tipoFundo
      };
    case actionItem.SET_TIPO_FUNDO_BACK:
      return {
        ...state,
        tipoFundoBack: action.payload.tipoFundo
      };
    case actionItem.TOGGLE_MODO_SOB_FIVELA:
      return {
        ...state,
        modoSobFivela: !state.modoSobFivela
      };
    case actionItem.SET_MODO_SOB_FIVELA:
      return {
        ...state,
        modoSobFivela: action.payload.modoSobFivela
      };
    case actionItem.UPDATE_IS_THUMB:
      return {
        ...state,
        isThumb: action.payload.isThumb
      };
    case actionItem.UPDATE_IS_BACK:
      return {
        ...state,
        isBack: action.payload.isBack
      };
    case actionItem.UPDATE_IS_SPINE_MODIFIED:
      return {
        ...state,
        isSpineModified: action.payload.isSpineModified
      };
    case actionItem.UPDATE_ORDER_ID:
      return {
        ...state,
        id: action.payload.id
      };
    case actionItem.UPDATE_SORT_METHOD:
      return {
        ...state,
        sortMethod: action.payload.sortMethod
      };
    case actionItem.UPDATE_FILTER_METHOD:
      return {
        ...state,
        filterMethod: action.payload.filterMethod
      };
    case actionItem.SET_PAGE_INDEX:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          index: action.payload.index
        }
      };
    case actionItem.ADD_PAGES:
      if (!action.payload.item) {
        return state;
      }
      return {
        ...state,
        page: Array.from(new Set([...state.page, ...action.payload.result]))
      };
    case actionItem.REMOVE_PAGE:
      return {
        ...state,
        page: state.page.filter(id => id !== action.payload.id)
      };
    case actionItem.REMOVE_PAGES:
      return {
        ...state,
        page: []
      };
    case actionItem.TOGGLE_REMOVE_PAGES:
      return {
        ...state,
        removePages: {
          active: !state.removePages.active,
          pages: state.removePages.active ? [] : state.removePages.pages
        }
      };
    case actionItem.SET_PAGES_TO_REMOVE:
      return {
        ...state,
        removePages: {
          ...state.removePages,
          pages: R.concat(state.removePages.pages, action.payload.pages)
        }
      };
    case actionItem.UNSET_PAGES_TO_REMOVE:
      return {
        ...state,
        removePages: {
          ...state.removePages,
          pages: state.removePages.pages.filter(
            page => !action.payload.pages.includes(page)
          )
        }
      };
    case actionItem.SET_ALL_USER_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        totalPrice: action.payload.totalPrice
      };
    case actionItem.SET_USER_PRODUCT:
      let newProducts = state.products;
      switch (action.payload.command) {
        case 'ADD':
          newProducts = [...state.products, action.payload.productId];
          break;
        case 'REMOVE':
          newProducts.splice(action.payload.index, 1);
          break;
        default:
          newProducts = [
            ...state.products.slice(0, action.payload.index),
            action.payload.productId,
            ...state.products.slice(action.payload.index + 1)
          ];
          break;
      }

      const totalPrice = calculateTotalPrice(
        newProducts,
        action.payload.products
      );
      return {
        ...state,
        products: [...newProducts],
        totalPrice: totalPrice
      };
    case actionItem.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload.product
      };
    case actionItem.SET_PRODUCT_STATUS:
      return {
        ...state,
        maxUserDiskSpace: action.payload.maxUserDiskSpace
          ? action.payload.maxUserDiskSpace
          : MAX_DISK_SPACE,
        maxImageCount: action.payload.maxImageCount
          ? action.payload.maxImageCount
          : MAX_IMAGE_COUNT
      };
    default:
      return state;
  }
};

export default reducer;
