import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';

import {
  getAllImagesThumb,
  getFilterMethod,
  getScreenSize,
  getSortMethod,
  getProductType,
  getMaxImageCount
} from '../selector';

import NavImage from '../component/nav-image';
import { POPUP_ID_REMOVE_IMAGES } from '../constant/popup';
import { REMOVE_IMAGES } from '../constant/popup-message';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  imagesLength: getAllImagesThumb(state).length,
  selectedSortId: getSortMethod(state),
  selectedFilterId: getFilterMethod(state),
  screenSize: getScreenSize(state),
  prodType: getProductType(state),
  maxImageCount: getMaxImageCount(state)
});

const mapDispatchToProps = dispatch => ({
  sortChangeHandler: sortMethod =>
    dispatch(actionItem.updateSortMethod(sortMethod)),
  filterChangeHandler: filterMethod =>
    dispatch(actionItem.updateFilterMethod(filterMethod)),
  inputFileChangeHandler: e => {
    dispatch(actionUI.setStateLegenda('MESA'));
    return dispatch(actionGeneral.sagaInputFileChange(e.currentTarget.files));
  },
  deleteAll: () => {
    dispatch(actionUI.setStateLegenda('POPUP'));
    return dispatch(
      actionUI.updatePopup(POPUP_ID_REMOVE_IMAGES, true, REMOVE_IMAGES())
    );
  },
  toggleBibliotecaTemas: () => dispatch(actionUI.toggleBibliotecaTemas())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavImage);
