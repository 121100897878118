import {
  bookHaveCover,
  getAllBookPagesData,
  getBookNavigatorData,
  getDragId,
  getEanUrl,
  getFormats,
  getSelectedProduct,
  getIsBookOverviewExpanded,
  getIsDragging,
  getIsGalleryExpanded,
  getIsMenuTextSelected,
  getItemIs3D,
  getNavigationIndex,
  getOverviewRemovePages,
  getPDFCoverLength,
  getPhotoEditor,
  getPrintOptions,
  getProductCode,
  getProductFormat,
  getProductType,
  getIsModoImpressao,
  getIsModoSobFivela,
  getScreenRate,
  getScreenSize,
  getSetSpineMargin,
  getSpineIndex,
  getTextOptions,
  isPrint,
  isThumb,
  getCurrentNav,
  getTamanhoColeira,
  getProductStyles,
  getTipoFundo,
  getFundoColor,
  getLayoutsFilter
} from '../../selector';
import {
  moveImage,
  sagaOverviewRemovePages,
  sagaSetRichText,
  sagaThumbDropImage
} from '../../action/general';
import { setPageIndex, unsetPagesToRemove } from '../../action/item';
import {
  startDrag,
  stopDrag,
  updateBookOverviewExpanded,
  updatePhotoEditor,
  updateScreenRate,
  updateSelectedNav,
  updateTextOptions,
  updateWarning,
  setStateLegenda
} from '../../action/ui';
import { NAV_TEXT_SELECTED } from '../../constant/nav';
import Photobook from '../../component/photobook';
import { connect } from 'react-redux';
import { getSetNumber } from '../../selector/set';
import store from '../../store';

const getXY = (event, isTouch) => {
  if (isTouch) {
    return {
      x: event.nativeEvent.targetTouches[0].pageX,
      y: event.nativeEvent.targetTouches[0].pageY
    };
  }
  return { x: event.nativeEvent.pageX, y: event.nativeEvent.pageY };
};

const normalizePos = (
  pos,
  dim,
  shapeDim,
  rotate,
  bleed,
  imgZoom,
  force = false
) => {
  const { top, bottom, left, right } = bleed;
  const isFullShape = top > 0 && bottom > 0 && left > 0 && right > 0;
  const isRotated = rotate !== 0 && rotate !== 180;

  const minX = !isFullShape ? -left : 0;
  const minY = !isFullShape ? -top : 0;

  const maxX = Math.floor(
    (isRotated
      ? Math.abs(shapeDim.height - dim.height) * -1
      : Math.abs(shapeDim.width - dim.width) * -1) + (isFullShape ? 0 : right)
  );
  const maxY = Math.floor(
    (isRotated
      ? Math.abs(shapeDim.width - dim.width) * -1
      : Math.abs(shapeDim.height - dim.height) * -1) +
      (isFullShape ? 0 : bottom)
  );

  const shapeWidth = shapeDim.width + left + right;
  const shapeHeight = shapeDim.height + top + bottom;
  let result = {
    x:
      (isRotated
        ? Math.floor(dim.height) > shapeHeight
        : Math.floor(dim.width) > shapeWidth) || force
        ? pos.x !== false
          ? Math.floor(pos.x)
          : false
        : false,
    y:
      (isRotated
        ? Math.floor(dim.width) > shapeWidth
        : Math.floor(dim.height) > shapeHeight) || force
        ? pos.y !== false
          ? Math.floor(pos.y)
          : false
        : false
  };

  if (result.x > minX && pos.x !== false && result.x !== false) {
    result = { x: minX, y: result.y };
  } else if (result.x < maxX && pos.x !== false && result.x !== false) {
    result = { x: maxX, y: result.y };
  }

  if (result.y > minY && pos.y !== false && result.y !== false) {
    result = { x: result.x, y: minY };
  } else if (result.y < maxY && pos.y !== false && result.y !== false) {
    result = { x: result.x, y: maxY };
  }

  return result;
};

const fixPos = (
  dispatch,
  pos,
  dim,
  shapeDim,
  id,
  rotate,
  bleed,
  imgZoom,
  force
) => {
  const normPos = normalizePos(
    { x: pos.x, y: pos.y },
    dim,
    shapeDim,
    rotate,
    bleed,
    imgZoom,
    force
  );
  dispatch(moveImage(id, normPos.x, normPos.y));
};

const moveHandler = (
  dispatch,
  event,
  isTouch,
  dim,
  shapeDim,
  id,
  rotate,
  bleed,
  imgZoom
) => {
  event.preventDefault();
  const state = store.getState();
  const currentMousePos = getXY(event, isTouch);
  const isDragging = getIsDragging(state);

  if (isDragging) {
    const { mousePos, pos } = state.ui.drag.start;

    if (currentMousePos.x !== mousePos.x || currentMousePos.y !== mousePos.y) {
      const photoPosX = pos.x + (currentMousePos.x - mousePos.x) * 2;
      const photoPosY = pos.y + (currentMousePos.y - mousePos.y) * 2;

      const normPos = normalizePos(
        { x: photoPosX, y: photoPosY },
        dim,
        shapeDim,
        rotate,
        bleed,
        imgZoom
      );

      dispatch(moveImage(id, normPos.x, normPos.y));
    }
  }
};

const moveKeyboardHandler = (
  dispatch,
  direction,
  step,
  pos,
  dim,
  shapeDim,
  id,
  rotate,
  bleed,
  imgZoom
) => {
  let photoPosX = pos.x;
  let photoPosY = pos.y;

  switch (direction) {
    case 'up':
      photoPosX = false;
      photoPosY -= step;
      break;
    case 'down':
      photoPosX = false;
      photoPosY += step;
      break;
    case 'right':
      photoPosX += step;
      photoPosY = false;
      break;
    case 'left':
      photoPosX -= step;
      photoPosY = false;
      break;
    default:
      break;
  }

  const normPos = normalizePos(
    { x: photoPosX, y: photoPosY },
    dim,
    shapeDim,
    rotate,
    bleed,
    imgZoom
  );

  dispatch(moveImage(id, normPos.x, normPos.y));
};

const mouseStartHandler = (dispatch, event, pos, isTouch, id) => {
  event.preventDefault();
  const { x, y } = getXY(event, isTouch);
  dispatch(startDrag(id, pos, { x, y }));
};

const textChange = (dispatch, id, pageId, textState, isFirst, x, y, l, a) => {
  dispatch(sagaSetRichText(id, pageId, textState, isFirst, false, x, y, l, a));
};

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  screenRate: getScreenRate(state),
  isPrint: isPrint(state),
  isThumb: isThumb(state),
  isScodix: getItemIs3D(state),
  spineIndex: getSpineIndex(state),
  printOptions: getPrintOptions(state),
  spineMargin: getSetSpineMargin(state),
  pageIndex: getNavigationIndex(state),
  isGalleryExpanded: getIsGalleryExpanded(state),
  isBookOverviewExpanded: getIsBookOverviewExpanded(state),
  navigatorData: getBookNavigatorData(state),
  allPagesData: getAllBookPagesData(state),
  isDragging: getIsDragging(state),
  dragId: getDragId(state),
  pagesFormats: getFormats(state),
  selectedProduct: getSelectedProduct(state),
  isMenuTextSelected: getIsMenuTextSelected(state),
  textOptions: getTextOptions(state),
  photoEditor: getPhotoEditor(state),
  overviewRemovePages: getOverviewRemovePages(state),
  setNumber: getSetNumber(state),
  bookHaveCover: bookHaveCover(state),
  coverLengthPDF: getPDFCoverLength(state),
  ean: getEanUrl(state),
  prodCode: getProductCode(state),
  productType: getProductType(state),
  productFormat: getProductFormat(state),
  currentNav: getCurrentNav(state),
  isModoImpressao: getIsModoImpressao(state),
  isModoSobFivela: getIsModoSobFivela(state),
  tamanhoSelecionado: getTamanhoColeira(state),
  styles: getProductStyles(state),
  tipoFundo: getTipoFundo(state),
  fundoColor: getFundoColor(state),
  layoutsFilter: getLayoutsFilter(state)
});

const mapDispatchToProps = dispatch => ({
  onShapeDrop: (thumbId, shapeId, pageIndex, pageId, styleId) =>
    dispatch(sagaThumbDropImage(thumbId, shapeId, pageIndex, pageId, styleId)),
  mouseStartHandler: (event, pos, isTouch, id) =>
    mouseStartHandler(dispatch, event, pos, isTouch, id),
  mouseStopHandler: () => dispatch(stopDrag()),
  moveHandler: (event, isTouch, dim, shapeDim, id, rotate, bleed, imgZoom) =>
    moveHandler(
      dispatch,
      event,
      isTouch,
      dim,
      shapeDim,
      id,
      rotate,
      bleed,
      imgZoom
    ),
  moveKeyboardHandler: (
    direction,
    step,
    pos,
    dim,
    shapeDim,
    id,
    rotate,
    bleed,
    imgZoom
  ) =>
    moveKeyboardHandler(
      dispatch,
      direction,
      step,
      pos,
      dim,
      shapeDim,
      id,
      rotate,
      bleed,
      imgZoom
    ),
  setPageIndex: index => dispatch(setPageIndex(index)),
  fixPos: (pos, dim, shapeDim, id, rotate, bleed, imgZoom, force) =>
    fixPos(dispatch, pos, dim, shapeDim, id, rotate, bleed, imgZoom, force),
  textChangeHandler: (id, pageId, textState, isFirst, x, y, l, a) =>
    textChange(dispatch, id, pageId, textState, isFirst, x, y, l, a),
  toggleNavText: () => dispatch(updateSelectedNav(NAV_TEXT_SELECTED)),
  updateTextOptions: (option, value, pageId) =>
    dispatch(updateTextOptions(option, value, pageId)),
  updatePhotoEditor: id => dispatch(updatePhotoEditor(id)),
  updateScreenRate: rate => dispatch(updateScreenRate(rate)),
  updateWarning: (value, text) => dispatch(updateWarning(value, text)),
  toggleBookOverviewExpanded: isBookOverviewExpanded =>
    dispatch(updateBookOverviewExpanded(!isBookOverviewExpanded)),
  setPageToRemove: pages => dispatch(sagaOverviewRemovePages(pages)),
  unsetPageToRemove: pages => dispatch(unsetPagesToRemove(pages)),
  setStateLegenda: state => dispatch(setStateLegenda(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(Photobook);
