import React from 'react';
import getTitle from './title';
import {
  getAdicionarProduct,
  getTipoGeneroProduto,
  applyMethodTodos
} from '../utils/textosVariaveis';

import store from '../store';
import {
  getProductType,
  getProductSource,
  getMaxImageCount
} from '../selector';
import {
  completarProdutoObrigatorio,
  fillPagesNotAddImage
} from '../utils/separadorProdutos';

import {
  getBtnRightImageUsed,
  getTitleFillPagesPhotosNeeded,
  getContentFillPagesPhotosNeeded,
  getTitleFillPagesPagesNeeded,
  getContentFillPagesPagesNeeded,
  getTitleFillClearBook,
  getContentClearBook,
  getBtnRightClearBook,
  getContentRemoveImages,
  getTitleRemovePages,
  getContentRemovePages,
  getTitlePageNotCompleteFilled,
  getContentPageNotCompleteFilled,
  getTitlePosterNotCompleteFilled,
  getContentPosterNotCompleteFilled,
  getTitleFinishBook,
  getContentFinishBook,
  getTitleToggleRemovePages,
  getContentToggleRemovePages,
  getBtnRightToggleRemovePages,
  getTitleConfirmRemovePages,
  getContentConfirmRemovePages,
  getBtnRightConfirmRemovePages,
  getTitleAlterarLayoutTodo,
  getContentAlterarLayoutTodo
} from '../utils/textosPopups';

export const IDENTIFY = {
  title: 'Identificação',
  content: [
    `Para conseguirmos te contatar em caso de qualquer imprevisto durante a sua criação, recomendamos fazer uma rápida identificação através do seu email:`
  ],
  btnLabel: {
    left: 'Agora não',
    right: 'Identificar'
  }
};

export const SENDING_IMAGES = () => ({
  title: 'Enviando as imagens...',
  content: [
    `Por favor, não feche esta página até o envio ser totalmente
  concluído.`
  ]
});

export const UNABLE_LOAD_IMAGE = {
  title: 'Algumas imagens não puderam ser carregadas',
  content: [
    `Para adicionar as imagens novamente, basta clicar no botão "Adicionar imagens".`
  ],
  btnLabel: {
    left: 'Voltar à Mesa',
    right: 'Adicionar imagens'
  }
};

export const FILL_PAGES_PHOTOS_NEEDED = () => ({
  title: getTitleFillPagesPhotosNeeded(),
  content: getContentFillPagesPhotosNeeded(),
  btnLabel: {
    left: 'Voltar à Mesa',
    right: 'Adicionar imagens'
  }
});

export const FILL_PAGES_PAGES_NEEDED = () => {
  const state = store.getState();
  const prodSource = state.library.product ? getProductSource(state) : '';
  const prodType = state.library.product ? getProductType(state) : '';
  return {
    title: getTitleFillPagesPagesNeeded(),
    content: getContentFillPagesPagesNeeded(),
    btnLabel: {
      left: 'Voltar à Mesa',
      right:
        prodSource === 'ML' || fillPagesNotAddImage(prodType)
          ? null
          : getAdicionarProduct(prodType)
    }
  };
};

export const CONFIRM_REMOVE_PAGES = () => ({
  title: getTitleConfirmRemovePages(),
  content: getContentConfirmRemovePages(),
  btnLabel: {
    left: 'Cancelar',
    right: getBtnRightConfirmRemovePages()
  }
});

export const CLEAR_BOOK = () => ({
  title: getTitleFillClearBook(),
  content: getContentClearBook(),
  btnLabel: {
    left: 'Cancelar',
    right: getBtnRightClearBook()
  }
});

export const REMOVE_IMAGES = () => ({
  title: 'Deseja remover todas as suas imagens não usadas da Mesa?',
  content: getContentRemoveImages(),
  btnLabel: {
    left: 'Cancelar',
    right: 'Remover imagens'
  }
});

export const FINISH_BOOK = () => ({
  title: getTitleFinishBook(),
  content: getContentFinishBook(),
  btnLabel: {
    left: 'Cancelar',
    right: 'Finalizar'
  }
});

export const REMOVE_PAGES = (string, pages) => ({
  title: getTitleRemovePages(),
  content: getContentRemovePages(string, pages),
  btnLabel: {
    left: 'Cancelar',
    right: 'Remover conteúdo'
  },
  pages
});

export const IMAGE_USED = page => ({
  title: 'Imagem está sendo usada',
  content: [
    `Esta imagem só poderá ser retirada da Mesa, depois que for retirada 
    ${getTipoGeneroProduto('d')} ${getTitle()}.`
  ],
  page,
  btnLabel: {
    left: 'Cancelar',
    right: getBtnRightImageUsed()
  }
});

export const UNABLE_SET_LAST_PAGE_LAYOUT = layout => ({
  title: `Sua troca de layouts do ${getTitle()}, não pode ser aplicada na última página`,
  content: [
    `O layout escolhido, não pode ser aplicado na última página.`,
    `Você pode aplicar o layout escolhido, e logo após, escolher um layout diferente para a última página, clicando em "Aplicar layout".`,
    `Ou clicar em "Cancelar", para escolher outro layout, que possa ser escolhido também para a última página.`
  ],
  btnLabel: {
    left: 'Cancelar',
    right: 'Aplicar layout'
  },
  layout
});

export const PAGE_NOT_COMPLETE_FILLED = (
  pagesMsg,
  proximaPaginaVazia,
  tudoVazio,
  faltaImagem
) => {
  const state = store.getState();
  const prodType = state.library.product ? getProductType(state) : '';
  return {
    title: getTitlePageNotCompleteFilled(tudoVazio, faltaImagem),
    content: getContentPageNotCompleteFilled(pagesMsg, tudoVazio, faltaImagem),
    btnLabel: {
      left: completarProdutoObrigatorio(prodType) ? null : 'Ignorar',
      right: 'Revisar'
    },
    proximaPaginaVazia
  };
};

export const POSTER_NOT_COMPLETE_FILLED = (pagesMsg, proximaPaginaVazia) => ({
  title: getTitlePosterNotCompleteFilled(),
  content: getContentPosterNotCompleteFilled(pagesMsg),
  btnLabel: {
    left: null,
    right: 'Revisar'
  },
  proximaPaginaVazia
});

export const POPUP_ID_FINISH_BOOK_TEXT = () => ({
  title: getTitleFinishBook(),
  content: getContentFinishBook(),
  btnLabel: {
    left: 'Cancelar',
    right: 'Finalizar'
  }
});

export const CONFIRM_UPDATE_APPLY_METHOD = type => {
  let title = applyMethodTodos(),
    content = '';

  switch (type) {
    case 'a todas as páginas da esquerda':
      title = 'de todas as páginas da esquerda';
      content = 'da esquerda ';
      break;
    case 'a todas as páginas da direita':
      title = 'de todas as páginas da direita';
      content = 'da direita ';
      break;
    default:
      break;
  }

  return {
    title: getTitleAlterarLayoutTodo(title),
    content: getContentAlterarLayoutTodo(content),
    btnLabel: {
      left: 'Cancelar',
      right: 'Alterar layout'
    },
    type
  };
};

export const POPUP_SCREEN_SIZE_ERROR_H3_MOBILE =
  'No momento, nossa Mesa de Criação não pode ser acessada nesse dispositivo';
export const POPUP_SCREEN_SIZE_ERROR_MESSAGE_MOBILE =
  'Desculpe, estamos trabalhando para tornar possível. Acesse nossa Mesa de Criação, em nosso site pelo computador';
export const POPUP_SCREEN_SIZE_ERROR_H3 = [
  'Atenção!',
  'A resolução atual é menor do que a resolução mínima ideal'
];
export const POPUP_SCREEN_SIZE_ERROR_MESSAGE =
  'Nossa Mesa de Criação não pode ser acessada, na resolução atual. Para voltar a acessá-la, aumente a janela do seu navegador.';

export const POPUP_TOGGLE_REMOVE_PAGES = setDif => ({
  title: getTitleToggleRemovePages(),
  content: getContentToggleRemovePages(setDif),
  btnLabel: {
    left: 'Cancelar',
    right: getBtnRightToggleRemovePages(setDif)
  }
});

export const ABANDON_PROJECT = () => ({
  title: 'Deseja abandonar o projeto?',
  content: [
    'Ao confirmar, esta ação não poderá ser mais desfeita., fechando a página atual e abandonando o projeto.'
  ],
  btnLabel: {
    left: 'Fechar página',
    right: 'Cancelar'
  }
});

export const REVISAR_MUDACA_ORIENTACAO = proximaPaginaVazia => ({
  title: 'Revise todas as imagens e textos',
  content: [
    'Devido a mudança de orientação, todas as imagens',
    'e textos presentes nos Pôsteres foram reajustados',
    'da melhor maneira possível.',
    'Mesmo assim, pedimos a sua revisão em todas as',
    'imagens e textos, antes de finalizar seus Pôsteres.'
  ],
  btnLabel: {
    left: null,
    right: 'Revisar'
  },
  proximaPaginaVazia
});

export const POPUP_FIREFOX_ANONIMO_TITLE =
  'Nossa Mesa de Criação não pode ser acessada no modo anônimo do Firefox';

export const POPUP_FIREFOX_ANONIMO_MESSAGE = () => (
  <>
    <p>Desculpe, estamos trabalhando</p>
    <p>para tornar possível.</p>
    <p>Acesse nossa Mesa de Criação,</p>
    <p>no modo normal do Firefox.</p>
  </>
);

export const POPUP_MAX_USER_SPACE = () => ({
  title: 'Armazenamento Cheio',
  content: [
    'Suas imagens enviadas até o momento, ocuparam',
    'todo o nosso armazenamento.',
    'Para continuar, por favor entre em contato conosco',
    'através do email rf@printastico.com.br.',
    'Enquanto não retornamos, deixe essa janela aberta',
    'e não faça nenhuma alteração no seu FotoProduto.'
  ]
});

export const POPUP_ID_MAX_IMAGE_COUNT = () => {
  const state = store.getState();
  const maxImageCount = getMaxImageCount(state);

  return {
    title: 'Limite de imagens atingido',
    content: [
      `Você já adicionou ${maxImageCount} imagens (nosso limite`,
      'máximo atual) na Mesa.',
      'Para conseguir adicionar novas imagens, você deve',
      'primeiro remover algumas imagens da Mesa.'
    ]
  };
};
