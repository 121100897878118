import './index.css';

import React from 'react';
import PropTypes from 'prop-types';
import RichText from '../rich-text';
import cuid from 'cuid';

import FrameComponent from '../frame';
import MakeImageBox from '../../container/image-box';
import Page from '../page';
import PlaceHolder from '../photo-placeholder';
import { isCustomRichText } from '../../utils/separadorProdutos';

import {
  framePropTypes,
  pagesFormatPropTypes,
  photoEditorPropTypes,
  shapePropTypes,
  textOptionsPropTypes,
  textPropTypes,
  stylePropTypes,
  textareaPropTypes
} from '../../proptypes';

import {
  calculoYMockPage,
  calculoHeightMockPage,
  calculoWidthMockPage,
  calculoHeightPage
} from '../../utils/calculosMockup';

import { buscaIndexStyle } from '../../utils/calculoRichText';
import { backgroundPage } from '../../utils/calculosGerais';
import { espelharProduto } from '../../utils/separadorProdutos';

const ImageBox = MakeImageBox(PlaceHolder);
const Frame = MakeImageBox(FrameComponent);

const testWidth = (isLeftPage, originalWidth, { left, width }) =>
  isLeftPage ? !left : left + width === originalWidth;
const testWidthTop = ({ top }) => !top;
const testHeightBottom = (originalHeight, { top, height }) =>
  top + height === originalHeight;
const testWidthtLeft = ({ left }) => !left;
const testHeightRight = (originalWidth, { left, width }) =>
  left + width === originalWidth;

const MockPage = ({
  id,
  x,
  y,
  width,
  height,
  originalWidth,
  originalHeight,
  pagesFormats,
  bleed,
  opacity,
  isLeftPage,
  shapes,
  textareas,
  styles,
  pageIndex,
  navigationIndex,
  onShapeDrop,
  styleId,
  frames,
  mouseStartHandler,
  mouseStopHandler,
  isDragging,
  dragId,
  moveHandler,
  moveKeyboardHandler,
  fixPos,
  texts,
  textChangeHandler,
  isMenuTextSelected,
  textOptions,
  rate,
  toggleNavText,
  updateTextOptions,
  photoEditor,
  updatePhotoEditor,
  isBookOverviewExpanded,
  isCover,
  isPrint,
  isThumb,
  bookHaveCover,
  setNumber,
  eanUrl,
  isScodix,
  coverLengthPDF,
  screenRate,
  updateWarning,
  productType,
  productFormat,
  isModoImpressao,
  isModoSobFivela,
  prodCode,
  setStateLegenda,
  tamanhoSelecionado,
  tipoFundo,
  fundoColor,
  layoutsFilter
}) => {
  const debru = !isThumb ? pagesFormats.cover.debru : 0;
  const bleedPrint = !isThumb ? bleed : 0;
  const bleedPrint2 = isPrint && !isThumb ? bleed : 0;

  const textareasProduct = buscaIndexStyle(
    productType,
    tamanhoSelecionado,
    textareas,
    styles
  );

  return (
    <svg
      x={x}
      y={y}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      style={{ overflow: 'visible' }}
    >
      <defs>
        <clipPath id={`mask-${id}`}>
          <rect
            x={isLeftPage && isCover && id !== 'spine' && !isPrint ? debru : 0}
            y={isCover && !isPrint ? debru : 0}
            width={
              isPrint || productType === 'dog-collar' ? width : originalWidth
            }
            height={isPrint ? height : originalHeight}
            id="mock-page-id"
          />
        </clipPath>
      </defs>
      <g clipPath={`url(#mask-${id})`}>
        <Page
          id={id}
          width={calculoWidthMockPage(
            width,
            isPrint,
            productType,
            productFormat
          )}
          height={calculoHeightPage(
            height,
            isPrint,
            productType,
            productFormat
          )}
          isLeftPage={isLeftPage}
          opacity={opacity}
          hasShadow={!isPrint && productType === 'book'}
          background={backgroundPage(
            productType,
            photoEditor.active,
            fundoColor
          )}
          isLastPage={setNumber === pageIndex}
          isPrint={isPrint}
          isThumb={isThumb}
          eanUrl={eanUrl}
          photoEditorActive={photoEditor.active}
          debru={isCover ? debru : 0}
          spineLogoShouldIncrement={isPrint && coverLengthPDF > 1}
          bookHaveCover={bookHaveCover}
          pagesFormats={pagesFormats}
          bleed={bleedPrint}
          bleedPattern={isPrint ? 0 : bleed}
          pageIndex={pageIndex}
          navigationIndex={navigationIndex}
          prodFormat={productFormat}
          prodType={productType}
          isModoImpressao={isModoImpressao}
          isModoSobFivela={isModoSobFivela}
          prodCode={prodCode}
          isCover={isCover}
          isBookOverviewExpanded={isBookOverviewExpanded}
          tamanhoSelecionado={tamanhoSelecionado}
          tipoFundo={tipoFundo}
          fundoColor={fundoColor}
          layoutsFilter={layoutsFilter}
          textareasProduct={textareasProduct}
          texts={texts}
          textOptions={textOptions}
          textChangeHandler={textChangeHandler}
          toggleNavText={toggleNavText}
          bleedPrint={bleedPrint2}
          rate={rate}
          updateTextOptions={updateTextOptions}
          isMenuTextSelected={isMenuTextSelected}
        >
          <React.Fragment>
            <svg
              width={calculoWidthMockPage(
                width,
                isPrint,
                productType,
                productFormat
              )}
              y={calculoYMockPage(height, isPrint, productType, productFormat)}
              height={calculoHeightMockPage(
                height,
                isPrint,
                productType,
                productFormat
              )}
              viewBox={`0 0 ${width} ${height}`}
              style={{ overflow: 'visible' }}
            >
              {shapes &&
                shapes.map(shape => {
                  const isWidth =
                      isCover && testWidth(isLeftPage, originalWidth, shape),
                    isTop = isCover && testWidthTop(shape),
                    isBottom =
                      isCover && testHeightBottom(originalHeight, shape);
                  return (
                    <ImageBox
                      key={shape.id}
                      type={shape.type}
                      points={shape.params}
                      scale={shape.scale}
                      width={shape.width + (isWidth ? debru : 0)}
                      height={
                        shape.height +
                        (isTop ? debru : 0) +
                        (isBottom ? debru : 0)
                      }
                      id={shape.id}
                      shapeId={shape.id}
                      x={
                        shape.left +
                        (isCover && isLeftPage && !isWidth ? debru : 0)
                      }
                      y={shape.top + (isCover && !isTop ? debru : 0)}
                      resizeMountain={{
                        addLeft: isCover && isLeftPage && isWidth ? debru : 0,
                        addTop: isTop && !isBottom && isCover ? debru : 0
                      }}
                      shape={shape}
                      pageId={id}
                      pageIndex={pageIndex}
                      styleId={styleId}
                      onDrop={onShapeDrop}
                      background={
                        isPrint
                          ? '#ffffff'
                          : photoEditor.active
                          ? '#3a3a3a'
                          : '#E6E6E6'
                      }
                      fill={photoEditor.active ? '#3f3f3f' : '#fff'}
                      photoEditorActive={photoEditor.active}
                      isBookOverviewExpanded={isBookOverviewExpanded}
                    />
                  );
                })}
              {textareasProduct &&
                !isCustomRichText(productType) &&
                textareasProduct.map((textarea, i) => (
                  <RichText
                    key={textarea.id}
                    pageId={id}
                    active
                    id={textarea.id}
                    richTextId={texts[i] ? texts[i].id : cuid()}
                    editorState={texts[i] ? texts[i].richTextState : ''}
                    textChangeHandler={textChangeHandler}
                    width={textarea.width}
                    widthAdjustment={textarea.width_adjustment}
                    height={textarea.height}
                    pageWidth={width}
                    pageHeight={height}
                    x={
                      textarea.left +
                      bleedPrint2 +
                      (isCover && isLeftPage && id !== 'spine' ? debru : 0) -
                      (id === 'spine' && isPrint && coverLengthPDF > 1
                        ? debru * 0.1
                        : 0)
                    }
                    xAdjustment={textarea.left_shift}
                    y={textarea.top + bleedPrint2 + (isCover ? debru : 0)}
                    color={texts[i] ? texts[i].textOptions.color : undefined}
                    family={texts[i] ? texts[i].textOptions.family : undefined}
                    size={texts[i] ? texts[i].textOptions.size : undefined}
                    align={texts[i] ? texts[i].textOptions.align : undefined}
                    bold={texts[i] ? texts[i].textOptions.bold : false}
                    italic={texts[i] ? texts[i].textOptions.italic : false}
                    underline={
                      texts[i] ? texts[i].textOptions.underline : false
                    }
                    defaultText={
                      textarea.default_text
                        ? textarea.default_text
                        : 'Digite seu texto aqui'
                    }
                    isMenuTextSelected={isMenuTextSelected}
                    textOptions={textOptions}
                    toggleNavText={toggleNavText}
                    updateTextOptions={updateTextOptions}
                    rate={rate}
                    photoEditorActive={photoEditor.active}
                    format={
                      productFormat && productFormat.split('-')[0]
                        ? productFormat.split('-')[0]
                        : ''
                    }
                    orientation={
                      productFormat && productFormat.split('-')[1]
                        ? productFormat.split('-')[1]
                        : ''
                    }
                    isBookOverviewExpanded={isBookOverviewExpanded}
                    isPrint={isPrint}
                    isLastPage={setNumber === pageIndex}
                    screenRate={screenRate}
                    isScodix={isScodix}
                    updateWarning={updateWarning}
                    isModoSobFivela={isModoSobFivela}
                    prodType={productType}
                    prodCode={prodCode}
                    tamanhoSelecionado={tamanhoSelecionado}
                    setStateLegenda={setStateLegenda}
                  />
                ))}
              {frames &&
                frames.map(frame => {
                  const isWidth =
                      isCover &&
                      testWidth(isLeftPage, originalWidth, frame.shape),
                    isTop = testWidthTop(frame.shape),
                    isBottom = testHeightBottom(originalHeight, frame.shape),
                    isLeft = testWidthtLeft(frame.shape),
                    isRight = testHeightRight(originalWidth, frame.shape);
                  return (
                    <Frame
                      key={frame.id}
                      width={
                        frame.shape.width +
                        (isWidth && productType !== 'mask' ? debru : 0) +
                        (isLeft && productType !== 'mask' ? bleedPrint : 0) +
                        (isRight && productType !== 'mask' ? bleedPrint : 0)
                      }
                      height={
                        frame.shape.height +
                        (isTop && productType !== 'mask'
                          ? isCover
                            ? debru
                            : isBottom
                            ? bleedPrint
                            : bleedPrint2
                          : 0) +
                        (isBottom && productType !== 'mask'
                          ? isCover
                            ? debru
                            : bleedPrint
                          : 0)
                      }
                      x={
                        frame.shape.left +
                        (!isLeft && productType !== 'mask' ? bleedPrint2 : 0) +
                        +(isCover && isLeftPage && !isWidth ? debru : 0)
                      }
                      y={
                        frame.shape.top +
                        (!isTop && !isCover && productType !== 'mask'
                          ? bleedPrint2
                          : 0) +
                        (isCover && !isTop ? debru : 0)
                      }
                      source={frame.image.source.image.blobUrl}
                      imgWidth={frame.image.source.image.width}
                      imgHeight={frame.image.source.image.height}
                      printSize={frame.image.metadata.originalDimensions}
                      imgPos={{ x: frame.posX, y: frame.posY }}
                      bleed={{
                        top: !isPrint && isTop ? bleed : 0,
                        bottom: !isPrint && isBottom ? bleed : 0,
                        left: !isPrint && isLeft ? bleed : 0,
                        right: !isPrint && isRight ? bleed : 0
                      }}
                      id={frame.id}
                      zoom={frame.zoom}
                      rotate={frame.rotate}
                      mouseStartHandler={mouseStartHandler}
                      mouseStopHandler={mouseStopHandler}
                      isDragging={isDragging}
                      isDraggingOver={isDragging && dragId === frame.id}
                      moveHandler={moveHandler}
                      moveKeyboardHandler={moveKeyboardHandler}
                      fixPos={fixPos}
                      pageIndex={pageIndex}
                      pageId={id}
                      styleId={styleId}
                      shapeId={frame.shape.id}
                      type={frame.shape.type}
                      points={frame.shape.params}
                      onDrop={onShapeDrop}
                      photoEditor={photoEditor}
                      updatePhotoEditor={updatePhotoEditor}
                      isBookOverviewExpanded={isBookOverviewExpanded}
                      isPrint={isPrint}
                      debru={isCover ? debru : 0}
                      screenRate={screenRate}
                      updateWarning={updateWarning}
                      changePos={{
                        left: isLeft && isLeftPage,
                        top: isTop,
                        right: isRight && !isLeftPage
                      }}
                      prodType={productType}
                      prodFormat={productFormat}
                      fundoColor={fundoColor}
                      background={backgroundPage(
                        productType,
                        photoEditor.active,
                        fundoColor
                      )}
                    />
                  );
                })}
            </svg>
            {espelharProduto(productType, styleId, styles) && (
              <svg>
                <g transform={`rotate(180)`}>
                  <svg
                    x={`-${width}`}
                    y={`-${height}`}
                    width={width}
                    height={height}
                    style={{ overflow: 'visible' }}
                  >
                    <defs>
                      <clipPath id={`mask-${id}`}>
                        <rect x={0} y={0} width={width} height={height} />
                      </clipPath>
                    </defs>
                    {textareasProduct &&
                      textareasProduct.map((textarea, i) => (
                        <RichText
                          key={textarea.id}
                          espelhado={true}
                          pageId={id}
                          active
                          id={textarea.id}
                          richTextId={texts[i] ? texts[i].id : cuid()}
                          editorState={texts[i] ? texts[i].richTextState : ''}
                          textChangeHandler={textChangeHandler}
                          width={textarea.width}
                          widthAdjustment={textarea.width_adjustment}
                          height={textarea.height}
                          pageWidth={width}
                          pageHeight={height}
                          x={
                            textarea.left +
                            bleedPrint2 +
                            (isCover && isLeftPage && id !== 'spine'
                              ? debru
                              : 0) -
                            (id === 'spine' && isPrint && coverLengthPDF > 1
                              ? debru * 0.1
                              : 0)
                          }
                          xAdjustment={textarea.left_shift}
                          y={textarea.top + bleedPrint2 + (isCover ? debru : 0)}
                          color={
                            texts[i] ? texts[i].textOptions.color : undefined
                          }
                          family={
                            texts[i] ? texts[i].textOptions.family : undefined
                          }
                          size={
                            texts[i] ? texts[i].textOptions.size : undefined
                          }
                          align={
                            texts[i] ? texts[i].textOptions.align : undefined
                          }
                          bold={
                            texts[i] ? texts[i].textOptions.bold : undefined
                          }
                          italic={
                            texts[i] ? texts[i].textOptions.italic : undefined
                          }
                          underline={
                            texts[i]
                              ? texts[i].textOptions.underline
                              : undefined
                          }
                          isMenuTextSelected={isMenuTextSelected}
                          textOptions={textOptions}
                          toggleNavText={toggleNavText}
                          updateTextOptions={updateTextOptions}
                          rate={rate}
                          photoEditorActive={photoEditor.active}
                          format={
                            productFormat && productFormat.split('-')[0]
                              ? productFormat.split('-')[0]
                              : ''
                          }
                          orientation={
                            productFormat && productFormat.split('-')[1]
                              ? productFormat.split('-')[1]
                              : ''
                          }
                          isBookOverviewExpanded={isBookOverviewExpanded}
                          isPrint={isPrint}
                          isLastPage={setNumber === pageIndex}
                          screenRate={screenRate}
                          isScodix={isScodix}
                          updateWarning={updateWarning}
                          isModoSobFivela={isModoSobFivela}
                          prodType={productType}
                          prodCode={prodCode}
                          tamanhoSelecionado={tamanhoSelecionado}
                          setStateLegenda={setStateLegenda}
                        />
                      ))}
                    {frames &&
                      frames.map(frame => {
                        const isWidth =
                            isCover &&
                            testWidth(isLeftPage, originalWidth, frame.shape),
                          isTop = testWidthTop(frame.shape),
                          isBottom = testHeightBottom(
                            originalHeight,
                            frame.shape
                          ),
                          isLeft = testWidthtLeft(frame.shape),
                          isRight = testHeightRight(originalWidth, frame.shape);
                        return (
                          <Frame
                            key={frame.id}
                            espelhado={true}
                            width={
                              frame.shape.width +
                              (isWidth && productType !== 'mask' ? debru : 0) +
                              (isLeft && productType !== 'mask'
                                ? bleedPrint
                                : 0) +
                              (isRight && productType !== 'mask'
                                ? bleedPrint
                                : 0)
                            }
                            height={
                              frame.shape.height +
                              (isTop && productType !== 'mask'
                                ? isCover
                                  ? debru
                                  : bleedPrint
                                : 0) +
                              (isBottom && productType !== 'mask'
                                ? isCover
                                  ? debru
                                  : bleedPrint
                                : 0)
                            }
                            x={
                              frame.shape.left +
                              (!isLeft && productType !== 'mask'
                                ? bleedPrint2
                                : 0) +
                              +(isCover && isLeftPage && !isWidth ? debru : 0)
                            }
                            y={
                              frame.shape.top +
                              (!isTop && !isCover && productType !== 'mask'
                                ? bleedPrint2
                                : 0) +
                              (isCover && !isTop ? debru : 0)
                            }
                            source={frame.image.source.image.blobUrl}
                            imgWidth={frame.image.source.image.width}
                            imgHeight={frame.image.source.image.height}
                            printSize={frame.image.metadata.originalDimensions}
                            imgPos={{ x: frame.posX, y: frame.posY }}
                            bleed={{
                              top: !isPrint && isTop ? bleed : 0,
                              bottom: !isPrint && isBottom ? bleed : 0,
                              left: !isPrint && isLeft ? bleed : 0,
                              right: !isPrint && isRight ? bleed : 0
                            }}
                            id={frame.id}
                            zoom={frame.zoom}
                            rotate={frame.rotate}
                            mouseStartHandler={mouseStartHandler}
                            mouseStopHandler={mouseStopHandler}
                            isDragging={isDragging}
                            isDraggingOver={isDragging && dragId === frame.id}
                            moveHandler={moveHandler}
                            moveKeyboardHandler={moveKeyboardHandler}
                            fixPos={fixPos}
                            pageIndex={pageIndex}
                            pageId={id}
                            styleId={styleId}
                            shapeId={frame.shape.id}
                            type={frame.shape.type}
                            onDrop={onShapeDrop}
                            photoEditor={photoEditor}
                            updatePhotoEditor={updatePhotoEditor}
                            isBookOverviewExpanded={isBookOverviewExpanded}
                            isPrint={isPrint}
                            debru={isCover ? debru : 0}
                            screenRate={screenRate}
                            updateWarning={updateWarning}
                            changePos={{
                              left: isLeft && isLeftPage,
                              top: isTop,
                              right: isRight && !isLeftPage
                            }}
                            prodType={productType}
                            prodFormat={productFormat}
                            fundoColor={fundoColor}
                            background={backgroundPage(
                              productType,
                              photoEditor.active,
                              fundoColor
                            )}
                          />
                        );
                      })}
                  </svg>
                </g>
              </svg>
            )}
          </React.Fragment>
        </Page>
      </g>
    </svg>
  );
};

MockPage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  originalWidth: PropTypes.number.isRequired,
  originalHeight: PropTypes.number.isRequired,
  pagesFormats: pagesFormatPropTypes,
  bleed: PropTypes.number.isRequired,
  opacity: PropTypes.number.isRequired,
  isLeftPage: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  navigationIndex: PropTypes.number.isRequired,
  onShapeDrop: PropTypes.func.isRequired,
  styleId: PropTypes.string.isRequired,
  mouseStartHandler: PropTypes.func.isRequired,
  mouseStopHandler: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  dragId: PropTypes.string.isRequired,
  moveHandler: PropTypes.func.isRequired,
  moveKeyboardHandler: PropTypes.func.isRequired,
  fixPos: PropTypes.func.isRequired,
  textChangeHandler: PropTypes.func.isRequired,
  isMenuTextSelected: PropTypes.bool.isRequired,
  textOptions: textOptionsPropTypes,
  rate: PropTypes.number.isRequired,
  toggleNavText: PropTypes.func.isRequired,
  updateTextOptions: PropTypes.func.isRequired,
  photoEditor: photoEditorPropTypes,
  updatePhotoEditor: PropTypes.func.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isCover: PropTypes.bool.isRequired,
  isPrint: PropTypes.bool.isRequired,
  isThumb: PropTypes.bool.isRequired,
  bookHaveCover: PropTypes.bool.isRequired,
  setNumber: PropTypes.number.isRequired,
  eanUrl: PropTypes.string.isRequired,
  isScodix: PropTypes.bool,
  coverLengthPDF: PropTypes.number,
  updateWarning: PropTypes.func.isRequired,
  screenRate: PropTypes.number.isRequired,
  productFormat: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  isModoImpressao: PropTypes.bool.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  shapes: PropTypes.arrayOf(shapePropTypes),
  textareas: PropTypes.arrayOf(textareaPropTypes),
  styles: PropTypes.arrayOf(stylePropTypes),
  frames: PropTypes.arrayOf(framePropTypes),
  texts: PropTypes.arrayOf(textPropTypes),
  prodCode: PropTypes.string.isRequired,
  setStateLegenda: PropTypes.func.isRequired,
  tamanhoSelecionado: PropTypes.number,
  tipoFundo: PropTypes.string.isRequired,
  fundoColor: PropTypes.string.isRequired,
  layoutsFilter: PropTypes.object.isRequired
};

MockPage.defaultProps = {
  opacity: 1,
  x: 0,
  y: 0,
  isDragging: false,
  dragId: undefined,
  shapes: [],
  stylePropTypes: [],
  frames: [],
  texts: []
};

export default MockPage;
